<template>
  <div class="page" v-loading="loading">
    <!-- 头部 -->
    <div class="dkzc-box">
      <div class="title">
        <i @click="$router.push('/index')" class="el-icon-arrow-left"></i>
        直播列表

        <el-button type="primary" v-show="isLive" @click="goPush"
          >我要直播</el-button
        >
      </div>
    </div>

    <!-- 直播列表 -->
    <ul class="list" v-if="content.length">
      <li
        @click="goBroadcastDetail(item)"
        v-for="item in content"
        :key="item.id"
      >
        <img :src="item.img" alt="" />
        <p class="type" :style="item.status | liveColor">
          <img
            v-show="item.status != 2"
            class="icon"
            src="@assets/image/liveBroadcast.png"
            alt=""
          />
          {{ item.status | liveStatus }}
        </p>
        <p v-show="item.status == 0" class="time">
          {{ item.start_time.substring(0, 16) }} 开始
        </p>
        <!-- <p v-show="item.status == 2" class="number">次播放</p> -->
        <p class="title">{{ item.title }}</p>
        <p class="hostipal">{{ item.anchor }}</p>
      </li>
    </ul>

    <!-- 暂无数据 -->
    <div class="null-box" v-else>
      <img
        :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
        alt=""
      />
      <div class="title">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "liveBroadcastList",
  data() {
    return {
      token: localStorage.getItem("token"),
      loading: true,
      content: [],
      usersig: "",
      isLive: false,
      stream_id: "",
    };
  },

  filters: {
    // 0未开始，1直播中，2已结束
    liveStatus(val) {
      switch (val) {
        case 0:
          val = "直播预告";
          break;
        case 1:
          val = "直播中";
          break;
        case 2:
          val = "观看回放";
          break;
      }
      return val;
    },

    liveColor(val) {
      switch (val) {
        case 0:
          val = "background:#3d91ff";
          break;
        case 1:
          val = "background:#03dbc6";
          break;
        case 2:
          val = "background:#fc7514";
          break;
      }
      return val;
    },
  },

  created() {
    this.getLiveList();
    this.getUserSig();
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },
  methods: {
    // 获取播放端用户签名
    async getUserSig() {
      let res = await this.$liveBroadcastApi.getUserSig({
        token: this.token,
      });
      if (res.code == 200) {
        this.usersig = res.data.usersig;
      } else {
        this.$message.error(res.message);
      }
    },

    // 获取直播列表
    async getLiveList() {
      let res = await this.$liveBroadcastApi.liveList({
        token: this.token,
      });
      this.loading = false;
      if (res.code == 200) {
        this.content = res.data;
        this.content.forEach((item) => {
          if (item.anchor_id == this.userInfo.id) {
            this.isLive = true;
            this.stream_id = item.stream_id;
          }
        });

        // this.userInfo.id
      } else {
        this.$message.error(res.message);
      }
    },

    // 点击跳转
    async goBroadcastDetail(item) {
      if (item.status == 0) {
        this.$message.error("直播未开始");
        return;
      }

      if (item.status == 2) {
        if (!item.record) {
          this.$message.error("回放暂未生成,请稍等");
          return;
        }
        this.$router.push({
          path: "/liveBroadcastDetail",
          query: {
            record: item.record.replace("http:", "https:"),
          },
        });
        return;
      }
      if (item.status == 1) {
        window.open(
          `/player/#/index/?roomId=${item.stream_id}&roomName=${item.title}&userId=${this.userInfo.id}&userName=${this.userInfo.nickname}&userAvatar=${this.userInfo.avatar}&usersig=${this.usersig}&anchorUserId=${item.stream_id}&info=${item.info}`
        );
      }
    },

    // 跳转到主播页面
    goPush() {
      console.log(this.userInfo.id);
      window.open(`/push?id=${this.stream_id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background: #f2f2f2;
  > .dkzc-box {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 42px;

      > .el-icon-arrow-left {
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
      }
      button {
        margin-left: 30px;
        cursor: pointer;
      }
    }
  }

  .list {
    width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    box-sizing: border-box;

    li {
      float: left;
      position: relative;
      cursor: pointer;
      width: 250px;
      height: 180px;
      list-style: none;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(30, 36, 58, 0.05);
      margin: 0 25px 30px 0;
      overflow: hidden;
      font-size: 12px;

      img {
        width: 100%;
      }

      .type {
        color: #fff;
        width: 80px;
        box-sizing: border-box;
        position: absolute;
        top: 5px;
        left: 5px;
        padding: 5px;
        border-radius: 2px;

        .icon {
          width: 10px;
        }
      }

      .time {
        width: 80px;
        position: absolute;
        top: 27px;
        left: 5px;
        border-radius: 2px;
        padding: 5px;
        background: #efefef;
        color: #919191;
        box-sizing: border-box;
      }

      .number {
        min-width: 80px;
        position: absolute;
        top: 27px;
        left: 5px;
        border-radius: 20px;
        padding: 5px;
        background: #efefef;
        color: #919191;
        box-sizing: border-box;
      }

      .title {
        position: absolute;
        left: 0;
        bottom: 32px;
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        color: #fff;
        background: rgba(0, 0, 0, 0.4);
      }

      .hostipal {
        width: 100%;
        height: 32px;
        background: #fff;
        color: #000;
        position: absolute;
        left: 0;
        bottom: 0px;
        padding: 3px 12px;
        line-height: 15px;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }
}
</style>
